import { useParams, Link, useNavigate } from "react-router-dom";
import DataContext from './context/DataContext';
import { useState, useEffect, useContext } from "react";
import { fetchData } from './api/fetchData';
import parse from 'html-react-parser';

import { Flex, Text, Button, Card, Box, Heading, Avatar, Callout } from '@radix-ui/themes';


function SingleLoadedWordCard({loadedWord, why, examples}) {
  const {loadedWordList } = useContext(DataContext);

    
    // console.log(string);  
  
  return (
   //sometyime typeis in beleif they;re looking for
   //toggel and search by problem instead
 

   
<Box maxWidth="300px">
  <Card variant='classic' size='3'>
    <Flex direction="column" gap="" align="">
        <Heading mb="" as="h1" >
        {loadedWord}
        </Heading>
        <h3>Why its a loaded word: </h3>
        {why && <Text as=""  mt="" color="gray" >  {parse(why.substring(0,1000))} </Text> }
           <Button mt="2" color="gray" variant="soft" style={{'align-self': 'center'}} >Click for more</Button>   
    </Flex>
  </Card>
</Box>

  //  <div className='beliefCard'>
  //   <h1>{loadedWord} </h1>
  //   <h2>Why its a loaded word: </h2>
  //    <p>{why}</p>
  //   <h3>Examples if exist</h3> 
  //     <p> {examples} substring 100 characters...</p>
  //  </div>
     
  );
}

export default SingleLoadedWordCard;



