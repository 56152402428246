import { Button, Flex, TextField } from '@radix-ui/themes'
import React from 'react'
import parse from 'html-react-parser';
import { fetchData, apiURL } from '../api/fetchData';


console.log(process.env.REACT_APP_API_SERVER!=='dev'? process.env.REACT_APP_API_SERVER: 'belief-api')


// REACT_APP_API_SERVER=http://localhost:
// API_SERVER=http://localhost:

//child object will send in parent; in future might support purchasing child objects
async function determineIfLockedUtil(condition, access){

    let showLock = false;
  
    let globalConditionAccess = await fetchData(apiURL + `/user/access/condition`);
    console.log(globalConditionAccess)
    let userGlobalConditionAccess = globalConditionAccess?.response?.data?.accesses.user
    console.log(globalConditionAccess)
   let nonUserGlobalConditionAccess = globalConditionAccess?.response?.data?.accesses.nonUser
  
    // console.log(globalConditionAccess?.response?.data?.accesses)
    // console.log(globalConditionAccess?.response?.data?.type)
  
    
    //logged in and not a condition free to all
    if(access && !userGlobalConditionAccess.includes(condition._id) )
      {
        console.log(access)
        let parentAccess = false;
        if(condition?.parentConditions && condition?.parentConditions.length >=1)
        {
          //check if we have access to any parent conditions via purchase
          condition?.parentConditions.forEach((parentId,i) => {
            if(access[parentId])
            {
              parentAccess = true;
            }
                
          })
          //are any of these parent condition in global list. check if global list contains a parent condition
         console.log('run the test'+ condition?.parentConditions.every(parentCondition => userGlobalConditionAccess.includes(parentCondition)))
         
          console.log('parent access' + parentAccess + condition?.parentConditions.includes(userGlobalConditionAccess))
          //check if anyparent conditions of the condition we're on is in global user access
          if(!parentAccess && condition?.parentConditions.some(parentCondition => userGlobalConditionAccess.includes(parentCondition)) )
            parentAccess=true;
        }
        //if no access then show lock should be true
         showLock = (access['subscribed'] || access[condition._id] || parentAccess ) ? false : true
         console.log('show lock when no parent conditions ' + showLock)

        //not logged in with access object and condition isn't social anxiety amd has no parent conditins which mean 
      } else if( !access && !nonUserGlobalConditionAccess.includes(condition._id) && !condition?.parentConditions  )
      {          
        showLock=true
        }
        //no access, not SA parent condition but a child condition
       else if( !access && !nonUserGlobalConditionAccess.includes(condition._id) && condition?.parentConditions  )
        {
          //if has a parent condition and parent dont contain social anxiety. if iterate through all parent conditionsn and find global list never contained any, show lock
          if (condition?.parentConditions.length >=1 && !condition?.parentConditions.some(parentCondition => nonUserGlobalConditionAccess.includes(parentCondition)))
          {console.log('non user global?', nonUserGlobalConditionAccess)
            showLock=true
          }
        }
  
  
        return showLock
    
  }
  



export {determineIfLockedUtil}