// import Header from './Header';
import Nav from './Nav';
// import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const Layout = ({  }) => {
    return (
        <div className="App">
            {/* <Header title="React JS Blog"  /> */}
            <Nav    />
            {/* outlet represents all the elemnts that will be included that aren't consistent reardless of whats happeneing */}
            <div className='main' style={{  }}>
            <Outlet /> 
            </div>
           
            {/* <Footer /> */}
        </div>
    )
}

export default Layout

//things that are always present?

