import logo from '../logo.svg';
// import '../App.css';
import { Link, useParams, useNavigate, useLocation} from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import DataContext from '../context/DataContext';
import parse from 'html-react-parser';

import { fetchData, deleteData, apiURL, postData } from '../api/fetchData';
import BeliefList from '../BeliefList';
import { Flex, Button, AlertDialog, Text, Heading, Em, Checkbox, IconButton, Box, Strong, Card, HoverCard, Callout, Switch } from '@radix-ui/themes';

import { checkIfAdmin } from '../Pages/Admin/utility';
import { getConditionsbyType } from '../api/conditions';
import FearTriggerCTA from '../Components/FearTriggerCTA';
import { hasAccessToObject, getAccessInfo } from '../api/userManagement';
import { CheckCircledIcon, InfoCircledIcon, Link2Icon } from '@radix-ui/react-icons';
import { determineIfLockedUtil } from './conditionUtility';
import PaymentInfo from '../Components/PaymentInfo';
import SignupInfo from '../Components/SignupInfo';
import { removeLinkStyling } from '../Components/universalStylingProps';



function searchForBelief(belief) {
  //move to belief list page; iterate through all beleifs and generate a single beleif component for each matching belief
  //use string provided and search through db list of beleifs and find relevatn matches. provide matches in a list for user to scroll through
}
 function ConditionPage() {
    const { loggedInUser,searchedForBelief, setsearchedForBelief, beliefListToDisplay, setBeliefListToDisplay  } = useContext(DataContext);
   const navigate = useNavigate();
    const { id } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasTriggers, setHasTriggers] = useState(false);
    const [pageisLocked, setPageIsLocked] = useState(true);

    const [hasFears, setHasFears] = useState(false);
    const [conditionToDisplay, setConditionToDisplay] = useState(null);
    const [showSignupInfo, setShowSignupInfo] = useState(false);



    const location = useLocation();


    

    function displaySignupInfo(e)
    {
      console.log(e)
      setShowSignupInfo(e)

    }

    

    
  const getCondition = async ()=> {
    // let apiURL = process.env.REACT_APP_API_SERVER + process.env.REACT_APP_PORT;

  
    let fetchedCondition = await fetchData(apiURL + `/conditions/${id}`);
    console.log(fetchedCondition)
    fetchedCondition = fetchedCondition?.response?.data;
   // setConditionsToDisplay(fetchedConditions);
   //get beleifs for condition - find beliefs that have this condition id 
//set condition list so conditon page can use to render a beleif list

  setConditionToDisplay(fetchedCondition)
  let fetchedBeliefs = await fetchData(apiURL + `/beliefs/condition/${id}`);
  console.log(fetchedBeliefs);
  //set beleif list which will be used to create belief cards on next page
  setBeliefListToDisplay(null)
  if(fetchedBeliefs?.response?.data.length >0)
   setBeliefListToDisplay(fetchedBeliefs?.response?.data);
  console.log(fetchedBeliefs?.response?.data);

}

const deleteCondition = async () => {
  console.log(id)
  console.log(`id we lliking for ${id}`)
  // let apiURL = process.env.REACT_APP_API_SERVER + process.env.REACT_APP_PORT;
  let reqBody = {'id':id}
  let deletedCondition = await deleteData( apiURL + `/conditions/${id}`, reqBody);
  console.log(`deleted condition for 1 pager is ${deletedCondition.response}`);
  //console.log(deletedBelief?.response.data);

  //deletedBelief = deletedBelief.response.data

  //going to this page means we fetch a belief that wont have conditions field of objects set 
  
  setConditionToDisplay(null)
    navigate('/conditions')

}


async function checkForMore() {
  //make api call to get conditions of type trigger with this condition jd /conditions/type
  let triggers = await getConditionsbyType(id, 'trigger')
  console.log(triggers)
  if(triggers && triggers.length > 0)
    setHasTriggers(true)


let fears = await getConditionsbyType(id, 'fear')
console.log(fears)
  if(fears && fears.length > 0)
    setHasFears(true)

}


async function isLocked(condition, access){

  let showLock = false;
 showLock = await determineIfLockedUtil(condition, access)
 console.log('wtf is locked0' + showLock)
/*
  let globalConditionAccess = await fetchData(apiURL + `/user/access/condition`);
  console.log(globalConditionAccess)
  globalConditionAccess = globalConditionAccess?.response?.data?.accesses.user
  console.log(globalConditionAccess)

  // console.log(globalConditionAccess?.response?.data?.accesses)
  // console.log(globalConditionAccess?.response?.data?.type)


  
  //logged in and not a condition free to all
  if(access && !globalConditionAccess.includes(condition._id) )
    {
      console.log(access)
      let parentAccess = false;
      if(condition?.parentConditions && condition?.parentConditions.length >=1)
      {
        //check if we have access to any parent conditions
        condition?.parentConditions.forEach((parentId,i) => {
          if(access[parentId])
          {
            parentAccess = true;
          }
              
        })
        //are any of these parent condition in global list. check if global list contains a parent condition
       console.log('run the test'+ condition?.parentConditions.every(parentCondition => globalConditionAccess.includes(parentCondition)))
        console.log('parent access' + parentAccess + condition?.parentConditions.includes(globalConditionAccess))
        //check if parent is social anxiety only if we dont already have access
        if(!parentAccess && condition?.parentConditions.every(parentCondition => globalConditionAccess.includes(parentCondition)) )
          parentAccess=true;
      }
      console.log(access['subscribed'] || access[condition._id])
      //if no access then show lock should be true
       showLock = (access['subscribed'] || access[condition._id] || parentAccess ) ? false : true
      //not logged in with access object and condition isn't social anxiety or a child condition of SA
    } else if( !access && condition._id!=='66df6fd0e95bed6217424ab1' && !condition?.parentConditions  )
    {          
      showLock=true
      }
      //no access, not SA parent condition but a child condition
     else if( !access && condition._id!=='66df6fd0e95bed6217424ab1' && condition?.parentConditions  )
      {
        //if has a parent condition and parent dont contain social anxiety
        if (condition?.parentConditions.length >=1 && !condition?.parentConditions.every(parentCondition => globalConditionAccess.includes(parentCondition)))
        {console.log('in here?')
          showLock=true
        }
      }
        */

      return showLock
  
}




useEffect(() =>{


async function init() {
  if(!conditionToDisplay) //tags havent been retrieved for this session (not in localstorage or current state)
  {
    console.log('is this being called')

    await getCondition();
  } 

  await checkIfAdmin(setIsAdmin); 
  if(isAdmin)
    setPageIsLocked(false)
  else
  {
    console.log(loggedInUser)
    let accessObject = await getAccessInfo(loggedInUser)
    console.log(accessObject);
    console.log(conditionToDisplay)
    if(conditionToDisplay)
    {
      let access = await isLocked(conditionToDisplay,accessObject);
      console.log(access)
      await checkForMore();

    setPageIsLocked(access)

    }
    // if(!conditionToDisplay.parentConditions || conditionToDisplay.parentConditions.length==0)
    //   {
    //     access = await hasAccessToObject(id, accessObject)
    //   } 
    //   else{
    //     //check if user has access to anything in the parent list

    //   }
    

  }

  
  

  // await checkForMore();

  //if anxiety, get triggers, fears, and set to pass to conditionlist to render
}

 init()
 



  const cleanUp = () => {
      // isMounted = false;
      // controller.abort()
      console.log('cleanup called')
      // source.cancel();
  }

  return cleanUp;
},[loggedInUser, conditionToDisplay]); //not sure fi this is actually needed

    
    
async function purchaseCondition() {
  let base = process.env.REACT_APP_ENV==='dev' ? process.env.REACT_APP_CLIENT_SERVER + process.env.REACT_APP_CLIENT_PORT : process.env.REACT_APP_PROD_CLIENT_SERVER

  insertEvent('click',{'button':'direct purchase'})

  let payload =  {
    "id": id,
    "username": loggedInUser,
    'objectType': 'condition',
    "successURL": base + `conditionPurchase/${id}?success=true`,
    "cancelURL": base + `conditionPurchase/${id}?canceled=true`
  }
  let {response} = await postData(apiURL + `/payment/createCheckoutSession`,payload);
  console.log(response)
  console.log(response.status)

  if(response && response?.status>=200)
      window.location.href = response?.data?.redirect
 // setConditionsToDisplay(fetchedConditions);


}

async function purchaseSubscription() {
  

  //insertEvent('click',{'button':'subscription'})

  let payload =  {
    "id": id,
    "username": loggedInUser,
    'objectType': 'condition'
  }

  insertEvent('click',{'button':'subscription'})

  let {response} = await postData(apiURL + `/payment/createSubscriptionCheckout`,payload);
  console.log(response)
  console.log(response.status)

  if(response && response?.status>=200)
      window.location.href = response?.data?.redirect
 // setConditionsToDisplay(fetchedConditions);


}

async function insertEvent(action,metadata)
{

  if(process.env.NODE_ENV==='prod')
  {
    let payload =  {
      "username": loggedInUser,
      'metadata': {...metadata, 'fileName':'ConditionPage.js'},
      "page": location.pathname,
      "action": action
    }
    console.log('location object', location)
  
     let {response} = await postData(apiURL + `/events/addEvent`,payload);
  
  }

}


 
    console.log(conditionToDisplay);
    
  return (
    
   //sometyime typeis in beleif they;re looking for
   //toggel and search by problem instead
  <div>
    
     <Flex  wrap ='true' justify='between'>
      {conditionToDisplay && <h1>{conditionToDisplay.condition} </h1>}

        {(conditionToDisplay && !pageisLocked) && <Button color='gold' highContrast size="3" mt="1"  onClick={() =>
          {
            insertEvent('click',{'button':'forum',"objectName":`${conditionToDisplay.condition}`})
            navigate(`/posts/condition/${id}/${conditionToDisplay.condition}`) 
       }
        } >Discuss this condition with others</Button>}
        </Flex>

        
    
    {/* updte this to be a array in list pulled from api */}
    {(conditionToDisplay && (!pageisLocked || id==='')) && 
      <div className=''>
        
       
      {/* <Text><Em>Note: If you are confused about anything in this solution or have other we encourage you to make a post in the <a href={`/posts/condition/${id}/${conditionToDisplay.condition}`}>discussion forum</a> asking for clarification</Em></Text> */}
          {/* tirggers people have, fears people face,  */}
          {hasTriggers && <a href={`/condition/${id}/trigger/${conditionToDisplay.condition}`} style={{removeLinkStyling}}><button href='test' style={{
          'background-color': '#641723', color: 'white', 'border-radius':'6px', border: 'none', 'margin-bottom':'5px', height: '48px', 'font-size': '18px', 'font-weight': '500'
        }} color="red" mb='2' mr="2" highContrast size="4" onClick={async() => {
            insertEvent('click',{'button':'triggers',"objectName":`${conditionToDisplay.condition}`})
          //  navigate(`/condition/${id}/trigger/${conditionToDisplay.condition}`)

            }}>Social anxiety triggers plus their solutions</button></a>}

        {hasFears && <a href={`/condition/${id}/fear/${conditionToDisplay.condition}`} style={{removeLinkStyling}}><Button color="blue" ml="2" highContrast size="4" onClick={async () => 
        {
          insertEvent('click',{'button':'fears',"objectName":`${conditionToDisplay.condition}`})
          // navigate(`/condition/${id}/fear/${conditionToDisplay.condition}`)
        }}
          >Solutions for the underlying fears that cause social anxiety</Button></a>}

<Callout.Root mt ='2' mb='2' size='1' color='gold' highContrast>
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
          Note: If you are confused about anything in this solution or have other problems not addressed below we encourage you to make a post in the <a href={`/posts/condition/${id}/${conditionToDisplay.condition}`}>discussion forum</a> asking for clarification or to share problems you have that weren't addressed.
          </Callout.Text>
  </Callout.Root>

        {/* <h2>Our Definition: </h2> */}
        {conditionToDisplay?.ourDefinition && parse(conditionToDisplay?.ourDefinition )}
        {conditionToDisplay?.thirdPartyDefinition?.sourceLink && <h3>Definition from  <a href={conditionToDisplay?.thirdPartyDefinition?.sourceLink}>{conditionToDisplay?.thirdPartyDefinition?.sourceLink}</a> </h3>}
        <p>{conditionToDisplay?.thirdPartyDefinition?.definition && parse(conditionToDisplay.thirdPartyDefinition.definition )}</p>
       { beliefListToDisplay && <><h2>{`Root cause limiting beliefs you may have to dismantle:`}</h2> 
        {/* belieflist shared state variable is set in condition list before navigateing to this page. will always be set because we either pass in from search or set when we pull condition data */}
        <Flex wrap="true"> <BeliefList ></BeliefList></Flex></>}

        

        {(conditionToDisplay?.type==='fear' || conditionToDisplay?.type==='trigger') && <FearTriggerCTA></FearTriggerCTA>}

        <Flex  wrap="wrap">
           <Button color="" size="3" mt="3" mr='2' highContrast onClick={() => 
           {
             insertEvent('click',{'button':'forum',"objectName":`${conditionToDisplay.condition}`})
            navigate(`/posts/condition/${id}/${conditionToDisplay.condition}`)
          }}>Discuss this condition with others</Button>

    <a style={{ display: 'block'
          
        }} href="https://forms.gle/os4qba2HZpTcvoWk6">
        <button color='gold' mt='3' ml='2' radius="Full"  style={{
          'background-color': '#8C7A5E', color: 'white', 'border-radius':'6px', border: 'none', 'margin-top':'12px', 'font-weight': '550', height:'40px'
        }} size="3"  variant="solid" highContrast > Provide feedback to improve this solution<Link2Icon />
        </button>
      </a>
     
        </Flex>

        {id==='66df6fd0e95bed6217424ab1' && 
        <Callout.Root mt='5' color="green">
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
          We are constantly updating our social anxiety offering to benefit our users and recommend you create an account to be notified of updates. To see some planned updates click this.  <Switch onCheckedChange={displaySignupInfo}></Switch>

    {showSignupInfo && <SignupInfo></SignupInfo>}
        {/* <HoverCard.Root>
       
        
            <HoverCard.Trigger>
              <Link> hover here</Link>
            </HoverCard.Trigger>
            <HoverCard.Content maxWidth="300px">
              <SignupInfo></SignupInfo>
            </HoverCard.Content>
            
	</HoverCard.Root>   */}
  {/* <Button onClick={() => navigate('/createAccount')} >Sign up to be notified of updates
            </Button> */}
</Callout.Text>
  </Callout.Root>
 }



        <h4>Looking for more?</h4>
        <p>If you'd like to understand this condition more or want 1on1 help overcoming it submit a request to get additional support. Otherwise, we suggest posting in the community which our experts watch. <Link to="https://forms.gle/JgudBDb8FKPcK8wGA">Get help with your unique situation. </Link> </p>
        
        <h4>Have something to add?</h4>
        <p>If there is anything here you think can be improved to help someone overcome this condition we'd love to hear it. We may update this page with your input. {<a href="https://forms.gle/os4qba2HZpTcvoWk6">Contribute</a>}</p>
        {isAdmin && 
        <> 
          <Button size='2' highContrast onClick={() => navigate(`/condition/${id}/edit`)}>Edit</Button>
          <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="red">Delete</Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Delete: {conditionToDisplay.condition}</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure? This word/phrase will no longer be accessible and deleted from the DB
                  
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button variant="solid" color="red" onClick={deleteCondition}>
                      Delete
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          </>}
    </div>
    }
    {/* {!conditionToDisplay && 
      // <p>Loading..</p>
     } */}
     { (pageisLocked && loggedInUser!=null) && <Flex direction='column' >
    
      <p>All non-social anxiety solutions require a subscription or direct purchase for access. To access the social anxiety solution or receive notifications whenever we update a solution just create a free <Link to="/createAccount" >account</Link>. You can opt out at anytime. <em>Note: Solutions for <a href='/beliefsearch'>dismantling a belief</a>, the <a href='/loadedWordSearch'>loaded word database</a>, and <a href='/insights'>premium insights</a> are currently free. </em>
         <br></br><br></br>
         To fund the creation of content and the operations of this site, condition solutions are on average ~2$. You can also get a 6$ subscription which gives you access to all existing and soon-to-be-released premium solutions, content, and forums on Clarity. </p>

      
     
     

     
        <Flex  justify='center' align='center'>
        <Button size='3' mr='2'highContrast onClick={purchaseCondition}>Direct purchase for lifetime access </Button>
           {/* subscriptipn box */}
           <Card ml='2'>
          <Flex maxWidth='250px' mt='3' ml='2' direction='column'>
            <Heading>Subscription</Heading>
            <Text size='3'>Monthly</Text>
            <Text size='1'>Access to all premium cotent and discussion forums on site. Includes future releases of all content (including condition solutions).
            </Text>
            <Text size='6'><Strong>$6 / mo</Strong></Text>

            <Box>
              <IconButton color="teal" variant="soft">
                  <CheckCircledIcon width="18" height="18" /> 
                </IconButton> 
                <Text size='3' ml='2'>Access to all existing and new solutions that will be released
                </Text>
            </Box>
            <Box mt='5'>
              <IconButton color="teal" variant="soft">
                  <CheckCircledIcon width="18" height="18" /> 
                </IconButton> 
                <Text  size='3' ml='2'>Access to existing and soon to be released premium insights. 
                </Text>
            </Box>
            <Box mt='5'>
              <IconButton color="teal" variant="soft">
                  <CheckCircledIcon width="15" height="15" /> 
                </IconButton> 
                <Text size='3' ml='2'>Access to all community forums and the ability to send feedback directly to our experts. 
                </Text>
            </Box>
            <Button color='indigo' onClick={purchaseSubscription}>Purchase Subscription </Button>
           <Text size='2'><Em >Note: Access to premium content is lost when your subscription is no longer active. You'll have lifetime access to any  premium content you purchased individually (without subscription) even when a subscription ends. If you have any questions feel free to contact us <a href={`${process.env.REACT_APP_CONTACT_FORM}`}>here</a> and select "I have a question' as the subject.</Em></Text>      
          </Flex>
          </Card>
            {/* end of subscirpiton box */}
        </Flex>
   
      
<Heading>Payment Issues?</Heading>
<Text>
If you have any issues making a payment please reach out to us at clarityapp1@gmail.com
</Text>
        {/* <Callout.Root highContrast mt='1'>
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text size='3'>
          If you have any issues making a payment please reach out to us at clarityapp1@gmail.com
          </Callout.Text>
  </Callout.Root>
         */}
      {/* <stripe-pricing-table disabled={true} pricing-table-id="prctbl_1Q7jjDBm8yO68FsgLPJOPObR"
publishable-key="pk_test_51Q5CkLBm8yO68FsgREoyysggMzs6sWfiHublue5zIf7SaaKMaTMagUtoU63dyfogwI4KTpJ2bh4NoAxhUO8r6ELN009BDY8NoS" customer-email={`${loggedInUser}`}>
</stripe-pricing-table>
       */}
     
      </Flex>
     }
      { (pageisLocked && loggedInUser==null) && <>
    
         <p>All non-social anxiety solutions require a subscription or direct purchase for access.  To access the social anxiety solution or receive notifications whenever we update a solution just create a free <Link to="/createAccount" >account</Link>. You can opt out at anytime. <em>Note: Solutions for <a href='/beliefsearch'>dismantling a belief</a>, the <a href='/loadedWordSearch'>loaded word database</a>, and <a href='/insights'>premium insights</a> are currently free. </em>
         <br></br><br></br>
         To fund the creation of content and the operations of this site, condition solutions are on average ~2$. You can also get a 6$ subscription which gives you access to all existing and soon-to-be-released premium solutions, contents, and forums on Clarity. To purchase or view a condition solution like this one you must first <Link to="/createAccount" >create an account. </Link> </p>
         </>
 }

       { pageisLocked && <PaymentInfo></PaymentInfo>
   }
  </div>
    
     
  );
}

export default ConditionPage;


// 
