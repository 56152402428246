import React from 'react';
import ReactDOM from 'react-dom/client';
import '@radix-ui/themes/styles.css';

// import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { Theme } from '@radix-ui/themes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <Router>
       <Routes>
       
          <Route path="/*" element ={ <Theme accentColor="gray" appearance=""> <App/> </Theme>}> </Route>
    
       </Routes>
       
   </Router>
 </React.StrictMode>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



