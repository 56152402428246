import { Text } from '@radix-ui/themes'
import React from 'react'

function SignupInfo() {
  return (
   <Text>
    <ul>
        <li> We plan to release real life counseling sessions showing how to uncover anxiety-causing unconscious beliefs so you can do the same for yourself.</li>
        <li> We will also be releasing a number of mindshifting perspectives to help you further chip away at your social anxiety, understand yourself more, and build self-confidence. </li>
        <li>
        Those with Clarity accounts will also have access to special discounts, offers, free giveaways, early access to premium content and solutions, and have the ability to vote on how we prioritize future solutions.
        </li>

    </ul>
 <br></br> 
   </Text>
  )
}

export default SignupInfo
